<template>
  <div>
    <el-menu default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
             :collapse="isCollapse" background-color="#002144" text-color="#ffffff" active-text-color="#387dff">
      <div class="box-title">
        <span>{{ isName }}</span>
      </div>

      <el-menu-item v-for="(item,index) in currentMenuData" :key="item.label" :index="index+''"
                    @click="gotoPage(item.path)">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      adminMenuData: [
        {
          path: '/main/home',
          name: "home",
          label: "用户管理",
          icon: "s-home",
          url: "home/home"
        },
        {
          path: '/main/ad',
          name: "ad",
          label: "广告管理",
          icon: "search",
          url: "home/home"
        },
        {
          path: '/main/behavior',
          name: "behavior",
          label: "行为日志",
          icon: "s-comment",
          url: "home/home"
        }
      ],
      advertiserMenuData: [
        {
          path: '/main/ad',
          name: "ad",
          label: "广告管理",
          icon: "search",
          url: "home/home"
        },
        {
          path: '/main/behavior',
          name: "behavior",
          label: "行为日志",
          icon: "s-comment",
          url: "home/home"
        }
      ]
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    gotoPage(path) {
      console.log(path);
      if (this.$route.path !== path && !(this.$root.path === '/home' && (path === '/'))) {
        this.$router.push(path);
      }
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.tab.isCollapse
    },
    isName() {
      return this.$store.state.tab.name
    },
    // 根据用户类型动态返回菜单数据
    currentMenuData() {
      const userType = localStorage.getItem("type"); // 获取用户类型
      console.log(userType);
      if (userType === "400000") { // 管理员
        return this.adminMenuData;
      } else if (userType === "400020") { // 广告主
        return this.advertiserMenuData;
      } else {
        return []; // 默认返回空数组
      }
    },

    ...mapState(['userInfo'])
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  height: 100vh;
  font-size: 18px;

  .box-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
  }
}
</style>